import {FrameworkConfiguration, PLATFORM} from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config
    // controls
    .globalResources(PLATFORM.moduleName('./elements/input-password/control'))
    .globalResources(PLATFORM.moduleName('./elements/input-text/control'))
    .globalResources(PLATFORM.moduleName('./elements/input-textarea/control'))
    .globalResources(PLATFORM.moduleName('./elements/input-checkbox/input-checkbox'))
    .globalResources(PLATFORM.moduleName('./elements/form-button/control'))
    .globalResources(PLATFORM.moduleName('./elements/ex-icon/control'))
    .globalResources(PLATFORM.moduleName('./elements/loader/control'))
    .globalResources(PLATFORM.moduleName('./body'))
    .globalResources(PLATFORM.moduleName('./elements/grid/grid'))
    .globalResources(PLATFORM.moduleName('./elements/wizard/wizard'))
    .globalResources(PLATFORM.moduleName('./behaviour/validate'))
    .globalResources(PLATFORM.moduleName('./elements/crisp-input-radio/crisp-input-radio'))
    .globalResources(PLATFORM.moduleName('./elements/crisp-picker/crisp-picker'))
    .globalResources(PLATFORM.moduleName('./elements/form-input-text/form-input-text'))
    .globalResources(PLATFORM.moduleName('./elements/form-input-radio/form-input-radio'))
    .globalResources(PLATFORM.moduleName('./elements/form-input-multiple/form-input-multiple'))
    .globalResources(PLATFORM.moduleName('./elements/form-input-bool/form-input-bool'))
    .globalResources(PLATFORM.moduleName('./elements/form-input-date/form-input-date'))
    .globalResources(PLATFORM.moduleName('./elements/form-input-address/form-input-address'))
    .globalResources(PLATFORM.moduleName('./elements/form-input-file/form-input-file'))
    .globalResources(PLATFORM.moduleName('./elements/form-input-telephone/form-input-telephone'))
    .globalResources(PLATFORM.moduleName('./elements/form-input-country/form-input-country'))
    .globalResources(PLATFORM.moduleName('./elements/form-input-contact/form-input-contact'))
    .globalResources(PLATFORM.moduleName('./elements/form-input-readonly/form-input-readonly'))
    .globalResources(PLATFORM.moduleName('./elements/help-text/help-text'))
    .globalResources(PLATFORM.moduleName('./elements/spinner/spinner'))
    
    // hooks
    .globalResources(PLATFORM.moduleName('./hooks/viewPortEnum'))

    // value converters
    .globalResources(PLATFORM.moduleName('./value-converters/date'))
    .globalResources(PLATFORM.moduleName('./value-converters/upper-case'))
    .globalResources(PLATFORM.moduleName('./value-converters/proper-case'))
    .globalResources(PLATFORM.moduleName('./value-converters/documentation-sort'))
    ;
}
