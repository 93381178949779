import { customElement, bindable, autoinject } from 'aurelia-framework';
import { Helpers } from 'application/helpers';

@customElement('help-text')
@autoinject()
export class HelpText {
    @bindable helpText: string;
    @bindable helpTitle: string;
    @bindable hideHelpTitle: string;
    
    showHelp: boolean = false;

    helpKeydown(event: KeyboardEvent) {
        return Helpers.keypressEnterOrSpace(event, () => this.showHelp = !this.showHelp);
    }
}
