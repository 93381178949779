import {FrameworkConfiguration, PLATFORM} from 'aurelia-framework';

import { ViewEngineHooks } from 'aurelia-templating';
import { ViewPorts } from '../../viewPort';

export class ViewPortEnumViewEngineHooks implements ViewEngineHooks {
  beforeBind(view) {
    view.overrideContext.Enums = view.overrideContext.Enums || {};
    view.overrideContext.Enums.ViewPorts = ViewPorts;
  }
}
