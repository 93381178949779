// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-ctr {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 32px;
  height: 32px;
  top: 12px;
  margin: 0 10px;
}

.spinner {
  pointer-events: none;
  width: 14px;
  height: 14px;
  border: 8px solid transparent;
  border-color: #eee;
  border-top-color: #aaa;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/resources/elements/spinner/spinner.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,cAAA;AACJ;;AAEA;EACC,oBAAA;EACA,WAAA;EACA,YAAA;EACA,6BAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kCAAA;AACD;;AAEA;EACC;IACE,yBAAA;EACD;AACF","sourcesContent":[".spinner-ctr {\r\n    display: inline-block;\r\n    position: relative;\r\n    overflow: hidden;\r\n    width: 32px;\r\n    height: 32px;\r\n    top: 12px;\r\n    margin: 0 10px;\r\n}\r\n\r\n.spinner {\r\n\tpointer-events: none;\r\n\twidth: 14px;\r\n\theight: 14px;\r\n\tborder: 8px solid transparent;\r\n\tborder-color: #eee;\r\n\tborder-top-color: #aaa;\r\n\tborder-radius: 50%;\r\n\tanimation: spin 1s linear infinite;\r\n}\r\n\r\n@keyframes spin {\r\n\t100% {\r\n\t\t\ttransform: rotate(360deg)\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
