// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes WizardSlideIn {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes WizardSlideOut {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -100%;
  }
}
wizard .wizard-content {
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
}
wizard .wizard-content wizard-step {
  display: block;
  width: 100%;
  margin-left: 0;
  top: 0;
}
wizard.au-animate .wizard-content wizard-step.au-leave-active {
  animation: WizardSlideOut 0.5s;
  position: absolute;
}
wizard.au-animate .wizard-content wizard-step.au-enter-active {
  animation: WizardSlideIn 0.5s;
}
wizard.au-animate .wizard-content.back wizard-step.au-leave-active {
  animation: WizardSlideIn 0.5s reverse;
  position: absolute;
}
wizard.au-animate .wizard-content.back wizard-step.au-enter-active {
  animation: WizardSlideOut 0.5s reverse;
}`, "",{"version":3,"sources":["webpack://./src/resources/elements/wizard/wizard.scss"],"names":[],"mappings":"AAEA;EACE;IACE,iBAAA;EADF;EAIA;IACE,cAAA;EAFF;AACF;AAKA;EACE;IACE,cAAA;EAHF;EAMA;IACE,kBAAA;EAJF;AACF;AAUE;EACE,cAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AARJ;AAWI;EACE,cAAA;EACA,WAAA;EACA,cAAA;EACA,MAAA;AATN;AAiBQ;EACE,8BAAA;EACA,kBAAA;AAfV;AAkBQ;EACE,6BAAA;AAhBV;AAuBU;EACE,qCAAA;EACA,kBAAA;AArBZ;AAwBU;EACE,sCAAA;AAtBZ","sourcesContent":["\r\n\r\n@keyframes WizardSlideIn {\r\n  0% {\r\n    margin-left: 100%;\r\n  }\r\n\r\n  100% {\r\n    margin-left: 0;\r\n  }\r\n}\r\n\r\n@keyframes WizardSlideOut {\r\n  0% {\r\n    margin-left: 0;\r\n  }\r\n\r\n  100% {\r\n    margin-left: -100%;\r\n  }\r\n}\r\n\r\n$wizardSlideDuration : 0.5s;\r\nwizard {\r\n\r\n  .wizard-content {\r\n    display: block;\r\n    width: 100%;\r\n    position: relative;\r\n    overflow: hidden;\r\n\r\n\r\n    wizard-step {\r\n      display: block;\r\n      width: 100%;\r\n      margin-left: 0;\r\n      top: 0;\r\n\r\n    }\r\n  }\r\n\r\n  &.au-animate {\r\n    .wizard-content {\r\n      wizard-step {\r\n        &.au-leave-active {\r\n          animation: WizardSlideOut $wizardSlideDuration;\r\n          position: absolute;\r\n        }\r\n\r\n        &.au-enter-active {\r\n          animation: WizardSlideIn $wizardSlideDuration;\r\n        }\r\n      }\r\n\r\n      &.back {\r\n        wizard-step {\r\n\r\n          &.au-leave-active {\r\n            animation: WizardSlideIn $wizardSlideDuration reverse;\r\n            position: absolute;\r\n          }\r\n\r\n          &.au-enter-active {\r\n            animation: WizardSlideOut $wizardSlideDuration reverse;\r\n          }\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
