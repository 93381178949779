import { customElement, bindable, useView, bindingMode, autoinject, containerless, PLATFORM } from 'aurelia-framework';
import { InputBase } from '../inputBase';

@customElement('input-textarea')
@useView(PLATFORM.moduleName('./control.html'))
@autoinject()
export class InputTextarea extends InputBase<string> {
    @bindable placeholder: string = '';
    @bindable hideLabel: boolean;
    @bindable maxLength?: number;
    @bindable showLength: boolean;
}
