// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messages {
  display: flex;
  justify-content: right;
}
.messages .errors {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/resources/elements/input-textarea/control.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACI;EACI,YAAA;AACR","sourcesContent":[".messages {\r\n    display: flex;\r\n    justify-content: right;\r\n\r\n    .errors {\r\n        flex-grow: 1;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
