import { BindingSignaler } from 'aurelia-templating-resources';
import { noView, children, bindable, child, inject, BindingEngine, customElement, processContent, TargetInstruction, useView, PLATFORM, autoinject, Animator, computedFrom, observable} from 'aurelia-framework';
import {ViewCompiler, ViewSlot, ViewResources, Container} from 'aurelia-framework';
import { EmptyStatement } from '@babel/types';
import { validate, ValidationError } from "validation";



@customElement('wizard')
@useView(PLATFORM.moduleName('./wizard.html'))
@autoinject

  //@processContent(function (viewCompiler: ViewCompiler, viewResources: ViewResources, element: Element, instruction: TargetInstruction) {
  //  var steps = processUserTemplate(element);
  //  viewCompiler.compile().create(
  //instruction.columns = result.columns;
  //instruction.rowAttributes = result.rowAttributes;
  //instruction.pager = result.pager;

  //return true;
  //})

export class Wizard{

  private contentArea: HTMLElement;
  constructor(private element: Element,
    vc: ViewCompiler,
    vr: ViewResources,
    container: Container,
    targetInstruction: TargetInstruction,
    bindingEngine: BindingEngine,
    private animator: Animator) {
    
  }

  private navigateDirection = 'forward';
  @children('wizard-step') private wizardSteps: WizardStep[] = [];
  private actualCurrentStep: WizardStep;

  @bindable showStepper: boolean = true;
  @bindable showDots: boolean = false;
  @bindable animate: boolean = true;

  @computedFrom('actualCurrentStep', 'wizardSteps')
  get currentStep() {
    return this.actualCurrentStep;
  }

  @computedFrom('actualCurrentStep', 'wizardSteps')
  get currentStepIndex() {
    return this.wizardSteps.indexOf(this.actualCurrentStep);
  }

  @computedFrom('wizardSteps.length')
  get totalSteps() {
    return this.wizardSteps.length;
  }

  private wizardStepsChanged(n, o) {
    this.wizardSteps.forEach(step => {
      (step as any).active = false;
    });
    this.forward();
  }

  public forward() {
    var target = this.wizardSteps[this.currentStepIndex + 1];
    this.goto(target);
  }

  public back() {
    if (this.currentStepIndex > 0) {
      var target = this.wizardSteps[this.currentStepIndex - 1];
      this.goto(target);
    }
  }
  @computedFrom('wizardSteps', 'actualCurrentStep', 'actualCurrentStep.valid', 'actualCurrentStep.canSkipInvalid')
  public get canGoForward() {
    var target = this.wizardSteps[this.currentStepIndex + 1];
    if (!target) return false;
    return this.canGoto(target);
  }
  @computedFrom('wizardSteps', 'actualCurrentStep', 'actualCurrentStep.valid', 'actualCurrentStep.canSkipInvalid')
  public get canGoBack() {
    if (this.currentStepIndex > 0) {
      var target = this.wizardSteps[this.currentStepIndex - 1];
      if (!target) return false;
      return this.canGoto(target);
    }

    return false;
  }

  public canGoto(step: WizardStep | number) {
    if (typeof step === "number") {
      step = this.wizardSteps[step];
    }
    if (step === undefined) return false;

    var actualtNextStep = this.nextValid(step);

    return step == actualtNextStep;
  }

  async stepClicked(step: WizardStep)
  {
    await this.actualCurrentStep.validate();
    setTimeout(() => this.goto(step), 10);
  }
  
  public goto(step: WizardStep | number) {
    if (typeof step === "number") {
      step = this.wizardSteps[step];
    }
    if (step === undefined) return;

    // get next valid step here based on validation rules etc
    step = this.nextValid(step);

    if (step == this.actualCurrentStep) return;

    if (!step.enabled) return;
    
    if (this.wizardSteps.indexOf(this.actualCurrentStep) < this.wizardSteps.indexOf(step)) {
      this.navigateDirection = 'forward';
    } else {
      this.navigateDirection = 'back';
    }

    (step as any).active = true;
    if (this.actualCurrentStep) (this.actualCurrentStep as any).active = false;
    this.actualCurrentStep = step;
  }

  private nextValid(step: WizardStep) {
    for (var i = Math.max(0, this.currentStepIndex); i < this.wizardSteps.length; i++) {
      var s = this.wizardSteps[i];
      if (!s.enabled) {
        continue;
      }

      if (s == step) {
        return s;
      }
      if (s.valid || s.canSkipInvalid) {
        continue;
      }
      if (!s.valid) {
        return s;
      }
    }
    return step;
  }
}

@customElement('wizard-step')
@useView(PLATFORM.moduleName('./wizard-step.html'))
export class WizardStep {
    hasAttached: boolean;
  constructor(private bindingSignaler: BindingSignaler, private element : Element, private animator : Animator) { }

  @bindable stepTitle = '';
  @bindable icon = null;
  @bindable description = '';
  @bindable({ changeHandler: 'notify' }) valid = true;
  @bindable({ changeHandler: 'notify' }) enabled = true;
  @bindable({ changeHandler: 'notify' }) visited = false;
  @observable private active = false;
  @observable visible = false;
  @bindable canSkipInvalid = false;

  async activeChanged(n, o) {
    if (n == o) return;

    if (n) {
      if (!this.visible) {
        this.visible = this.active;
        if (this.hasAttached) await this.animator.enter(this.element as HTMLElement);
      }
    } else {
      if (this.visible) {
        if (this.hasAttached) await this.animator.leave(this.element as HTMLElement);
        this.visible = this.active;
      }
    }
  }

  init() {
    this.visible = false;
  }

  attached() {
    this.hasAttached = true;
  }

  notify() { this.bindingSignaler.signal('wizard-step-changed'); }

  public async validate() : Promise<ValidationError[]> {
    const wizardContent: any = this.element.children[1];
    
    if (wizardContent.au && wizardContent.au.controller.viewModel) {
      return await validate(wizardContent.au.controller.viewModel);
    }

    return [];
  }
}
