import { Evidence } from "application/components/evidence-item";
import { Contact } from "resources/elements/form-input-contact/form-input-contact";

export class DocumentationSortValueConverter {
    toView(evidence: Evidence[], additionalClaimants: Contact[]) {
        return evidence.slice(0).sort((a: Evidence, b: Evidence) => {
            const sortOrderA = this.evidenceSortOrder(a, additionalClaimants);
            const sortOrderB = this.evidenceSortOrder(b, additionalClaimants);
            return sortOrderA < sortOrderB ? -1 : sortOrderA > sortOrderB ? 1 : 0;
        });
    }

    evidenceSortOrder(evidence: Evidence, additionalClaimants: Contact[]) {
        switch (evidence.evidenceType) {
            case "Proof of Name":
                const i = additionalClaimants.findIndex(x => x.id === evidence.contactId);
                return i === -1 ? 0 : i + 2;
            case "Proof of Address":
                const ix = additionalClaimants.findIndex(x => x.id === evidence.contactId);
                return ix === -1 ? 1 : ix + 3;
            case "Proof of Ownership of property":
                return additionalClaimants.length + 4;
            case "Copy of Pre-purchase Survey":
                return additionalClaimants.length + 5;
            case "Letter of Authority to act on behalf of the policyholder":
                return additionalClaimants.length + 6;
            case "Copy of the Signed Lease / Deed of Conditions / Tenancy Agreement":
                return additionalClaimants.length + 7;
            case "Details of the Service Charge Apportionment":
                return additionalClaimants.length + 8;
        }

        return 99;
    }
}
