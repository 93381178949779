import { customElement, bindable, useView, bindingMode, autoinject, containerless, PLATFORM, computedFrom} from 'aurelia-framework';
import { ValidationError } from "class-validator";
import { bindValidationResult, bindValidationFired, ValidationTriggers } from '../behaviour/bindValidationResult';

let sharedInputCounter: number = 0;


export class InputBase<T> {
  @bindable public name: string = "";
  @bindable public label: string = "";
  @bindable public subtitle: string;
  @bindable public note: string;
  @bindable public showNote: boolean = false;
  @bindable({ defaultBindingMode: bindingMode.twoWay })  public value: T;

  private inputCounter: number;

  @bindable helpTitle = 'What does this mean?';
  @bindable hideHelpTitle = 'Hide explanation';
  @bindable helpText: string;
  @bindable delayValidation : boolean = false;

  @bindValidationResult()
  private errorResult: ValidationError;
  
  @bindValidationFired(ValidationTriggers.AutoValidation)
  private validationTriggeredAuto: boolean = false;
  
  @bindValidationFired(ValidationTriggers.ManualValidation)
  private validationTriggeredManual: boolean = false;

  private visited: boolean = false;

  public markVisited(){
    this.visited = true;
  }

  @computedFrom('validationTriggeredAuto', 'validationTriggeredManual')
  private get validationTriggered() {
    return this.validationTriggeredManual || (!this.delayValidation && this.validationTriggeredAuto);
  }

  @computedFrom('inputCounter', 'name')
  protected get fieldName() {
    if (!!this.name) {
      return name;
    } else {
      return `input-${this.inputCounter}`;
    }
  }

  @computedFrom('hasValidationErrors', 'visited', 'validationTriggered')
  protected get showValidationErrors() {
    return this.hasValidationErrors && (this.visited || this.validationTriggered);
  }

  @computedFrom('errorResult')
  protected get hasValidationErrors() {
    if (this.errorResult) {
      return true;
    } else {
      return false;
    }
  }

  @computedFrom('validationMessages')
  protected get validationMessage() {
    var msg = '';
    this.validationMessages.forEach(k => {
      if (msg) msg += '\n';
      msg += k;
    });
    return msg;
  }

  @computedFrom('errorResult')
  get validationMessages() {
    if (this.errorResult && this.errorResult.constraints) {
      return Object.keys(this.errorResult.constraints).map(k => {
        return this.errorResult.constraints[k];
      });
    } else {
      return [];
    }
  }

  constructor() {
    this.inputCounter = ++sharedInputCounter;
  }
}

